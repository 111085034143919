import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { filter, map, Observable, OperatorFunction, switchMap } from 'rxjs';
import { CreateUserDto, UserResponseDto } from '../../../../../aok-backend/src/user/dto/create-user.dto';
import { env } from '../../../environments/environment';

const USER_BASE_URL = `${env.apiUrl}/user`;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private http: HttpClient,
    public auth: AuthService,
  ) {}

  createUser(newUser: CreateUserDto): Observable<UserResponseDto> {
    return this.auth.user$.pipe(
      filter((user) => !!user) as OperatorFunction<User | null | undefined, User>,
      map((user) => user.sub),
      switchMap((sub) => {
        newUser.authZeroId = sub ? sub : 'not_found';
        return this.http.post<UserResponseDto>(USER_BASE_URL, newUser);
      }),
    );
  }

  getUserByEmail(email: string): Observable<UserResponseDto> {
    return this.auth.user$.pipe(
      filter((user) => !!user) as OperatorFunction<User | null | undefined, User>,
      map((user) => user.sub),
      switchMap((sub) => {
        return this.http.get<UserResponseDto>(`${USER_BASE_URL}/email/${email}`);
      }),
    );
  }

  getUserById(userId: string): Observable<UserResponseDto> {
    return this.auth.user$.pipe(
      filter((user) => !!user) as OperatorFunction<User | null | undefined, User>,
      map((user) => user.sub),
      switchMap((sub) => {
        return this.http.get<UserResponseDto>(`${USER_BASE_URL}/${userId}`);
      }),
    );
  }
}
